import {
  doc,
  getDoc,
  collection,
  or,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  addDoc,
  serverTimestamp,
  limit,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteField,
} from "firebase/firestore";
import { db } from "../firebase";
import UserAPI from "./UserAPI";
import Utility from "../utils/utility";

class EventsAPI {
  static collectionName = "events";

  static async getAllEvents() {
    try {
      const eventsQuery = query(
        collection(db, EventsAPI.collectionName),
        where("isActive", "==", true)
      );
      const querySnapshot = await getDocs(eventsQuery);
      const events = [];
      querySnapshot.forEach((doc) =>
        events.push({ id: doc.id, ...doc.data() })
      );
      return events;
    } catch (error) {
      throw error;
    }
  }
  static getEventStreaming(eventId, callback) {
    try {
      const docRef = doc(db, EventsAPI.collectionName, eventId);
      return onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          callback({ id: doc.id, ...doc.data() });
        } else {
          console.log("No such document!");
        }
      });
    } catch (error) {
      throw error;
    }
  }
  static getEventStreamingWithGroupChatCount(
    eventId,
    subCollectionName,
    timestamp,
    callback
  ) {
    try {
      const docRef = doc(db, EventsAPI.collectionName, eventId);

      return onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const eventData = { id: doc.id, ...doc.data() };

          const subCollectionRef = collection(
            db,
            EventsAPI.collectionName,
            eventId,
            subCollectionName
          );

          if (!(timestamp instanceof Date)) {
            timestamp = Utility.convertDate(timestamp);
          }
          const q = query(subCollectionRef, where("timestamp", ">", timestamp));

          onSnapshot(q, (querySnapshot) => {
            const subCollectionCount = querySnapshot.size;
            callback({
              ...eventData,
              [`${subCollectionName}Count`]: subCollectionCount,
            });
          });
        } else {
          console.log("No such document!");
        }
      });
    } catch (error) {
      throw error;
    }
  }
  static async getEventsFor(userID) {
    try {
      const eventsQuery = query(
        collection(db, EventsAPI.collectionName),
        or(
          where("sitters", "array-contains", userID),
          where("movers", "array-contains", userID)
        )
      );
      const querySnap = await getDocs(eventsQuery);
      const events = [];
      querySnap.forEach((doc) => events.push({ id: doc.id, ...doc.data() }));
      return events;
    } catch (err) {
      console.error(err);
    }
  }

  static async addUserToEvent(eventId, userId, type) {
    const eventRef = doc(db, EventsAPI.collectionName, eventId);
    const eventDoc = await getDoc(eventRef);
    const propertyName = type;

    const usersRef = doc(db, UserAPI.collectionName, userId);



    if (eventDoc.exists()) {
      const eventData = eventDoc.data();
      const attendees = eventData[propertyName] || [];

      if (!attendees.includes(userId)) {
        await updateDoc(eventRef, {
          [propertyName]: arrayUnion(userId),
        });

        await updateDoc(usersRef, {
          [`group.${eventId}`]: type
        })

        const usersDoc = await getDoc(usersRef);
        return usersDoc.data();

      } else {
        console.log(`User ${userId} is already in event ${eventId}.`);
      }
    } else {
      console.log(`Event ${eventId} does not exist.`);
    }
  }

  static async removeUserFromEvent(eventId, userId) {
    try {
      const eventRef = doc(db, EventsAPI.collectionName, eventId);
      const eventDoc = await getDoc(eventRef);

      const usersRef = doc(db, UserAPI.collectionName, userId);


      if (!eventDoc.exists()) {
        console.log(`Event ${eventId} does not exist.`);
        return;
      }

      const eventData = eventDoc.data();
      const groups = [Utility.userType.sitters, Utility.userType.movers];
      let userFound = false;

      for (const group of groups) {
        const users = eventData[group] || [];

        if (users.includes(userId)) {
          await updateDoc(eventRef, {
            [group]: arrayRemove(userId),
          });

          await updateDoc(usersRef, {
            [`group.${eventId}`]: deleteField(),
          });

          const usersDoc = await getDoc(usersRef);

          console.log(`User ${userId} removed from ${group} in event ${eventId}.`);
          return usersDoc.data();
        }
      }

      if (!userFound) {
        console.log(`User ${userId} is not part of event ${eventId}.`);
      }
    } catch (error) {
      console.error(`Error removing user ${userId} from event ${eventId}:`, error);
    }
  }

  static async lastCheckedChat(eventID, userID) {
    const lastCheckedChatTS = {
      [`lastCheckedChat.${userID}`]: serverTimestamp(),
    };
    await EventsAPI.updateEvent(eventID, lastCheckedChatTS);
  }

  static async updateEvent(eventID, valueObject) {
    try {
      const eventRef = doc(db, EventsAPI.collectionName, eventID);

      // Fetch the document
      const docSnap = await getDoc(eventRef);

      if (!docSnap.exists()) {
        console.log("No matching event found.");
        return null;
      }

      // Update the document
      await updateDoc(eventRef, valueObject);

      // Fetch the updated document
      const updatedDoc = await getDoc(eventRef);
      const updatedEvent = updatedDoc.data();

      console.log("Event updated successfully.");
      return updatedEvent;
    } catch (error) {
      console.error("Error updating event: ", error);
      throw error;
    }
  }

  static async onUpdateUserInQueue(user, eventData, group, userID) {
    if (eventData?.queue) {
      const userType = group[eventData.id];
      const existingRecordIdx = eventData.queue[userType].findIndex(
        (u) => u && u.userID == userID
      );
      if (existingRecordIdx > -1) {
        Object.keys(user).forEach((field) => {
          eventData.queue[userType][existingRecordIdx][field] = user[field];
        });
        const updateEventQueueUser = {
          [`queue.${userType}`]: eventData.queue[userType],
        };
        await EventsAPI.updateEvent(eventData.id, updateEventQueueUser);
      }
    }
  }
  static async deleteFieldProperty(documentId, fields) {
    // Reference to the specific document
    const docRef = doc(db, EventsAPI.collectionName, documentId);

    // Update the document, deleting the specified field
    const updateObject = {};
    fields.forEach((field) => {
      updateObject[field] = deleteField();
    });

    await updateDoc(docRef, updateObject);

    // console.log(`Field "${field}" has been deleted from document "${documentId}" in collection "${collection}".`);
  }
}

export default EventsAPI;
