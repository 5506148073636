import React, { useState, useEffect, useContext, useCallback } from "react";

import "./DateSession.scss";
import useHideNav from "../../utils/useHideNav";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { arrayRemove, arrayUnion } from "firebase/firestore";
import { Toolbar } from "./DateSessionHelpers";
import {
  EndSessionPopup,
  ReportUserPopup,
  SessionSheet,
} from "./DateSessionHelpers";
import MainSection from "./MainSection";
import EventsAPI from "../../api/EventsAPI";
import Utility from "../../utils/utility";
import Notifications, {
  TEXT_NOTIFICATION_FIELD,
} from "../../components/Popup/Notifications";

const ACTIVE_SESSION_FIELD = "activeSession";

export const DateSession = () => {
  const navigate = useNavigate();
  const { eventID } = useParams();
  const { globalState } = useContext(GlobalContext);

  const [sessionTime, setSessionTime] = useState(4 * 60);
  const [eventData, setEventData] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);
  const [oppGenderUserProfiles, setOppGenderUserProfiles] = useState([]);
  const [currentRound, setCurrentRound] = useState(0);
  const [currentDaterIdx, setCurrentDaterIdx] = useState(0);
  const [showIDCard, setShowIDCard] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [endSessionPopup, setEndSessionPopup] = useState(false);
  const [reportUserPopup, setReportUserPopup] = useState(false);
  const [reportUserText, setReportUserText] = useState("");
  const [hasActiveSession, setHasActiveSession] = useState(false);
  const [breaks, setBreaks] = useState(0);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [userType, setUserType] = useState(globalState?.user?.group[eventID]);
  const [showSessionSheet, setShowSessionSheet] = useState(false);
  const [sheetType, setSheetType] = useState("");

  const onCloseEndSessionPopup = () => setEndSessionPopup(false);
  const onCloseReportUserPopup = () => {
    setReportUserText("");
    setReportUserPopup(false);
  };
  const showNotifications = () => {
    setShowNotificationPopup(true);
  };
  const closeNotifications = () => {
    setShowNotificationPopup(false);
  };
  const onReportUserBtnPress = async () => {
    setReportUserPopup(true);
  };

  const onReportSend = async () => {
    const reportItem = {
      reportUserID: oppGenderUserProfiles[currentDaterIdx].userID,
      reportName: oppGenderUserProfiles[currentDaterIdx].name,
      note: reportUserText,
      reportedByUserID: globalState.user.userID,
      reportedByName: globalState.user.name,
    };
    await EventsAPI.updateEvent(eventID, {
      reports: arrayUnion(reportItem),
    });
    onCloseReportUserPopup();
  };

  const backToDates = () => {
    navigate(`/dates/date-details/${eventID}`, {
      state: { event: eventData },
    });
  };

  const backToCheckIn = () => {
    navigate(`/dates/check-in/${eventID}`, {
      state: { event: eventData },
    });
  };
  useHideNav();

  useEffect(() => {
    const unSub = EventsAPI.getEventStreaming(eventID, (e) => {
      setEventData(e);
    });
    addSession();
    return () => {
      if (unSub) unSub();
    };
  }, []);

  useEffect(() => {
    if (eventData.activeSession && eventData.activeSession.currentRound) {
      setHasActiveSession(true);

      setSessionTime(eventData.activeSession.timeLeft);
      if (eventData.isCompleted) {
        onEndSession();
        return;
      }
      setCurrentSessionData();
    }
    if (
      eventData.activeSession &&
      eventData.activeSession.currentRound === undefined
    ) {
      console.log("No active session. back to dates");
      backToDates();
    }
  }, [eventData.activeSession]);

  const calculateTimeLeft = useCallback(() => {
    if (!eventData.activeSession || !eventData.activeSession.at) return;

    const elapsedTime =
      (Date.now() - Utility.convertDate(eventData.activeSession.at).getTime()) /
      1000;
    return Math.max(
      0,
      Math.floor(eventData.activeSession.timeLeft - elapsedTime) +
      breaks * sessionTime
    );
  }, [eventData.activeSession, breaks]);

  useEffect(() => {
    let timer;
    if (eventData.activeSession) {
      const updateTimeLeft = () => {
        setTimeLeft(calculateTimeLeft());
      };

      updateTimeLeft();
      timer = setInterval(updateTimeLeft, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [eventData.activeSession, calculateTimeLeft]);

  useEffect(() => {
    if (hasActiveSession || !globalState.user) return;

    if (globalState.user) {
      setUserType(globalState.user.group[eventData.id || eventID]);
    }

    if (eventData.queue) {
      const oppUserType = Utility.getOppositeType(userType);
      const queueOfOppGender = eventData.queue[oppUserType];
      const daterList = queueOfOppGender.map(
        (u) => u || { name: "On-Break ", isOnBreak: true }
      );
      setOppGenderUserProfiles(daterList);
    }
  }, [eventData.queue, globalState.user]);

  useEffect(() => {
    if (oppGenderUserProfiles[currentDaterIdx]?.isOnBreak === true) {
      const isSitter = userType == Utility.userType.sitters;

      const consecutiveBreaks = Utility.getConsecutiveBreakCount(
        oppGenderUserProfiles,
        currentDaterIdx,
        isSitter ? "backward" : "forward"
      );
      setBreaks(consecutiveBreaks);
    }
  }, [currentDaterIdx, oppGenderUserProfiles]);

  const onExit = () => {
    setEndSessionPopup(true);
  };

  const onEndSession = async (toDateDetails = true) => {
    removeSession();
    onCloseEndSessionPopup();
    if (rating || reviewText.length) {
      const review = {
        userID: globalState.user.userID,
        name: globalState.user.name,
        rating,
        reviewText,
      };
      EventsAPI.updateEvent(eventID, { review: arrayUnion(review) });
    }
    toDateDetails ? backToDates() : backToCheckIn();
  };

  const removeSession = async () => {
    EventsAPI.updateEvent(eventID, {
      [`${ACTIVE_SESSION_FIELD}.activeUsers`]: arrayRemove(
        globalState.user.userID
      ),
    });
  };

  const addSession = () => {
    EventsAPI.updateEvent(eventID, {
      [`${ACTIVE_SESSION_FIELD}.activeUsers`]: arrayUnion(
        globalState.user.userID
      ),
    });
  };

  const getDaterIndex = () => {

    const queue = eventData.queue[userType];
    const userIndex = queue.findIndex(
      (u) => u && u.userID == globalState.user?.userID
    );

    const isSitter = userType == Utility.userType.sitters;
    const daterIndex = Utility.calculateRoundRobinDistance(
      userIndex,
      eventData.activeSession.currentRound,
      queue.length,
      isSitter
    );

    return daterIndex;
  };

  const setCurrentSessionData = () => {
    const daterIndex = getDaterIndex();
    setCurrentDaterIdx(daterIndex);
    setCurrentRound(eventData.activeSession.currentRound);
  };

  const onSessionSheetCancel = () => {
    setShowSessionSheet(false);
  };

  const onShowSessionSheet = (type) => {
    setSheetType(type);
    setShowSessionSheet(true);
  };

  const onUpdateCueSheet = () => {
    setSheetType("profile");
    setShowSessionSheet(true);
  };

  const onShowGroupChat = () => {
    setSheetType("group-chat");
    setShowSessionSheet(true);
  };

  const onAllowTextNotificationsChangee = (isChecked) => {
    const newUserState = {
      phone: isChecked ? globalState.user.phone : null,
      [TEXT_NOTIFICATION_FIELD]: isChecked,
    };
    EventsAPI.onUpdateUserInQueue(
      newUserState,
      eventData,
      globalState.user.group,
      globalState.user.userID
    );
  };

  return (
    <div className="date-session">
      <Toolbar
        onExit={onExit}
        user={oppGenderUserProfiles[currentDaterIdx]}
        setShowIDCard={setShowIDCard}
        currentRound={currentRound}
        onUpdateCue={onUpdateCueSheet}
        onGroupChat={onShowGroupChat}
        onShowNotifications={showNotifications}
        totalRounds={eventData?.queue?.movers?.length}
      />
      <MainSection
        timeLeft={timeLeft}
        user={oppGenderUserProfiles[currentDaterIdx]}
        currentRound={currentRound}
        sessionTime={sessionTime}
        onReport={onReportUserBtnPress}
        onShowSessionSheet={onShowSessionSheet}
        showIDCard={showIDCard}
        setShowIDCard={setShowIDCard}
      />
      <EndSessionPopup
        isOpen={endSessionPopup}
        onClose={onCloseEndSessionPopup}
        onConfirm={onEndSession}
        rating={rating}
        setRating={setRating}
        reviewText={reviewText}
        setReviewText={setReviewText}
      />
      <ReportUserPopup
        isOpen={reportUserPopup}
        onClose={onCloseReportUserPopup}
        onConfirm={onReportSend}
        reportUserText={reportUserText}
        setReportUserText={setReportUserText}
      />
      <SessionSheet
        onCancel={onSessionSheetCancel}
        showSessionSheet={showSessionSheet}
        setShowSessionSheet={setShowSessionSheet}
        type={sheetType}
        eventData={eventData}
      />
      {showNotificationPopup && (
        <Notifications
          isOpen={true}
          onClose={closeNotifications}
          onChange={onAllowTextNotificationsChangee}
        />
      )}
    </div>
  );
};
