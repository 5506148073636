import React, { useEffect, useState, useContext } from "react";
import "./DatesList.scss";
import Api from "../api/api";
import Utility from "../utils/utility";
import { useNavigate } from "react-router-dom";
import EventsAPI from "../api/EventsAPI";
import { GlobalContext } from "../context/GlobalContext";

const DatesList = ({ path = "/event-details" }) => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const handleEventClick = (item) => {
    navigate(`/dates/date-details/${item.id}`, { state: { event: item } });
  };

  const [showLoading, setShowLoading] = useState(true);
  const { globalState } = useContext(GlobalContext);

  const fetchEvents = async () => {
    try {
      const eventsData = await EventsAPI.getEventsFor(
        globalState.user.userID
      );

      setEvents(eventsData);
      setShowLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  useEffect(() => {
    if (globalState.user) fetchEvents();
  }, [globalState.user]);

  return (
    <div className="dates-container [height:calc(100dvh-65px)] overflow-y-auto">
      <h2 className="text-lg font-bold">Attending</h2>

      <div className="list">
        {showLoading && <div className="loader"></div>}
        {events.length
          ? events.map((item) => {
            return (
              <DatesItem
                key={item.id}
                item={item}
                hasActiveSession={item.activeSession?.at && !item.isCompleted}
                onClick={(_) => handleEventClick(item)}
              />
            );
          })
          : !showLoading && (
            <div className="no-items">Sign up on Events Tab</div>
          )}
      </div>
    </div>
  );
};

export default DatesList;

function DatesItem({ item, onClick, hasActiveSession }) {
  if (item == undefined) return null;

  return (
    <div
      className="flex items-center py-4 border-b border-gray-200 cursor-pointer"
      onClick={onClick}
    >
      <img
        src={item.photos[0]}
        alt={item.name}
        className="w-20 h-20 rounded-lg object-cover mr-4"
      />
      <div className="flex flex-col">
        <div className="text-gray-500 text-sm flex justify-between">
          <span className="">
            {Utility.formatDate(item.date)}
          </span>

          {
            hasActiveSession &&
            <span className="relative flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
            </span>
          }
        </div>
        <div className="text-md font-bold">{item.name}</div>
        <div className="text-gray-600 text-sm">
          {item.hostname} ({item.ageRange})
        </div>
      </div>
    </div>
  );
}
