// Join.js
import React, { useState, useEffect, useContext } from "react";
import Api from "../api/api";
import {
  PhoneIcon,
  XMarkIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";
import "./Join.scss";
import UserAPI from "../api/UserAPI";
import { GlobalContext } from "../context/GlobalContext";
import { motion } from "framer-motion";
import useImageResizer from "../utils/useImageResizer";
import { PrimaryButton, SecondaryButton } from "../components/Buttons/buttons";
import { Input, Select } from "../components/Input/input";
import Utility from "../utils/utility";

const PrefixCaptcha = ({ onLogin, onCancel }) => {
  return (
    <div>

      <Join onLogin={onLogin} onCancel={onCancel} />
      <div id="captcha"></div>
    </div>

  )
}

// Main App Component
const Join = ({ onLogin, onCancel }) => {
  const [step, setStep] = useState(1);
  const [signInData, setSignInData] = useState({});

  const handleNext = () => setStep(step + 1);
  const handlePrev = () => setStep(step - 1);
  const { setGlobalState } = useContext(GlobalContext);

  const cacheUser = (user) =>
    localStorage.setItem("user", JSON.stringify(user));
  const onBeforeLogin = (user) => {
    setGlobalState((prevState) => ({ ...prevState, user: user }));
    cacheUser(user);
    onLogin(user);
  };

  return (
    <div className="join">
      <div className="cancelArea" onClick={onCancel}></div>

      <motion.div
        className="container glass m-auto"
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        exit={{ y: "100vh" }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="closeBtn" onClick={onCancel}>
          <XMarkIcon />
        </div>
        <div className="logo">
          <img src="/gist_logo.jpg" className="logo-img" />
        </div>
        {step === 1 && (
          <PhoneInput
            signInData={signInData}
            setSignInData={setSignInData}
            onNext={handleNext}
          />
        )}
        {step === 2 && (
          <OtpInput
            signInData={signInData}
            setSignInData={setSignInData}
            onLogin={onBeforeLogin}
            onNext={handleNext}
            onBack={handlePrev}
          />
        )}
        {step === 3 && (
          <UserDetails
            signInData={signInData}
            onSignUpComplete={onBeforeLogin}
            onBack={handlePrev}
          />
        )}
      </motion.div>

    </div>
  );
};

// PhoneInput Component
const PhoneInput = ({ onNext, signInData, setSignInData }) => {
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("1");
  const [showLoading, setShowLoading] = useState(false);

  const [authErrorMsg, setAuthErrorMsg] = useState(null)

  const countryCodes = [
    { code: "1", name: "United States / Canada" },
    { code: "7", name: "Russia" },
    { code: "33", name: "France" },
    { code: "34", name: "Spain" },
    { code: "39", name: "Italy" },
    { code: "44", name: "United Kingdom" },
    { code: "49", name: "Germany" },
    { code: "52", name: "Mexico" },
    { code: "55", name: "Brazil" },
    { code: "63", name: "Philippines" },
    { code: "81", name: "Japan" },
    { code: "82", name: "South Korea" },
    { code: "84", name: "Vietnam" },
    { code: "86", name: "China" },
    { code: "91", name: "India" },
  ];
  useEffect(() => {
    if (signInData.fullNumber) {
      const trimmedNumber = signInData.fullNumber.substring(
        2,
        signInData.fullNumber.length
      );
      setPhone(formatPhoneNumber(trimmedNumber));
    }
  }, []);

  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return !match[2]
        ? match[1]
        : `${match[1]}-${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    if (e.target.value.length > 12) return;
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhone(formattedPhone);
  };

  const handleNext = async () => {
    if (phone.length < 12) return alert("Please enter a valid phone number");
    // Add phone number validation here
    setShowLoading(true);
    if (!window.appVerifier) {
      window.appVerifier = Api.setupRecaptcha("captcha", signInStart);
    }

    signInStart();
  };

  const signInStart = async () => {
    const fullNumber = `+${countryCode}${phone}`;
    const confirmationResult = await Api.signInWithPhone(
      fullNumber,
      window.appVerifier
    );
    if (!confirmationResult) {
      console.error("something went wrong");
      window.location.reload();
      return;
    };

    if (!confirmationResult.verificationId) {
      if (confirmationResult.code) {
        const err = <div className="line-clamp-3 text-rose-500 text-sm">
          {confirmationResult.code} -
          {confirmationResult.name} -
          {confirmationResult.message}
        </div>
        setAuthErrorMsg(err)
        setShowLoading(false);
        return;
      } else {
        window.location.reload()
      }

    }


    setSignInData({
      fullNumber,
      confirmationResult,
    });

    if (confirmationResult.verificationId) {
      setShowLoading(false);
      onNext();
    }

  };

  return (
    <div className="phone">
      <div className="text-sm text-slate-400" >
        We never share your phone number
      </div>
      <div className="stdInput">
        <PhoneIcon />
        <select
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
        >
          {countryCodes.map((country) => (
            <option key={country.code} value={country.code}>
              +{country.code}
            </option>
          ))}
        </select>
        <input
          type="tel"
          value={phone}
          onChange={handlePhoneChange}
          className="phoneInput"
          autoComplete="tel"
          placeholder="Phone Number"
        />

      </div>

      <PrimaryButton onClick={handleNext} disabled={showLoading}>
        {showLoading ? (
          <div className="loader m-auto">
            <p>{"\u200B"}</p>
          </div>
        ) : (
          <p>Next</p>
        )}
      </PrimaryButton>

      <div className="">
        {authErrorMsg}
      </div>

    </div>
  );
};

// OtpInput Component
const OtpInput = ({ setSignInData, signInData, onLogin, onNext, onBack }) => {
  const [otp, setOtp] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const handleNext = async () => {
    if (otp.length < 6) return alert("Incomplete Pass");
    // Add OTP validation here
    setShowLoading(true);
    const user = await Api.verifyCode(signInData.confirmationResult, otp);

    const userData = await UserAPI.getUserByID(user.uid);
    if (userData.length) {
      onLogin(userData[0]);
    } else {
      setSignInData((prev) => {
        return {
          ...prev,
          otp,
          user,
        };
      });
      setShowLoading(false);
      onNext();
    }
  };

  return (
    <div className="space-y-3 height-cal-150 flex flex-col justify-center">
      <Input
        label="Verification Code"
        type="text"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        placeholder="Verification Code"
      />

      <PrimaryButton onClick={handleNext} disabled={showLoading}>
        {showLoading ? (
          <div className="loader m-auto">
            <p>{"\u200B"}</p>
          </div>
        ) : (
          <p>Verify</p>
        )}
      </PrimaryButton>
      <SecondaryButton onClick={onBack}>Back</SecondaryButton>
    </div>
  );
};

// UserDetails Component
const UserDetails = ({ signInData, onSignUpComplete, onBack }) => {
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [photo, setPhoto] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const { setImageForResize } = useImageResizer(600, 800);
  const handleSignUp = async () => {
    // Add sign up logic here

    const isFullName = fullName.split(" ").length > 1;

    if (!isFullName) {
      return alert("Provide full name: First & Last");
    }
    const isPhoto = photo != null;
    if (!isPhoto) {
      return alert("Please upload a photo");
    }
    setShowLoading(true);

    const photoURL = await UserAPI.uploadUserProfilePhoto(
      signInData.user.uid,
      photo
    );
    const user = {
      phone: signInData.fullNumber,
      name: fullName,
      photoURL,
      userID: signInData.user.uid,
    };
    await UserAPI.addUser(user);
    onSignUpComplete(user);
    setShowLoading(false);
  };

  const handlePhotoUpload = async (e) => {
    const resizedPhoto = await setImageForResize(e.target.files[0]);
    setPhoto(resizedPhoto);
  };

  return (
    <div className="space-y-3 height-cal-150 flex flex-col justify-center">
      <Input
        placeholder="First & Last Name"
        type="text"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
      />

      {/* <Select
        label
        type="text"
        value={gender}
        onChange={(e) => setGender(e.target.value)}
        options={[Utility.gender.male, Utility.gender.female]}
      /> */}


      <div className="w-full flex flex-col items-center">
        <label className="text-primary ring-primary ring-1 bg-white py-2 px-4 rounded hover:bg-primary-dark w-full text-xl flex items-center justify-center relative">
          {photo && (
            <span className="absolute left-4">
              <CheckCircleIcon className="w-6 h-6 text-secondary" />
            </span>
          )}
          <span className="w-full text-center">Upload Photo</span>
          <input
            type="file"
            onChange={(e) => handlePhotoUpload(e)}
            accept="image/*"
            className="hidden"
          />
        </label>
      </div>
      <PrimaryButton
        onClick={handleSignUp}
        disabled={showLoading}
        className="primary"
      >
        {showLoading ? (
          <div className="loader m-auto">
            <p>{"\u200B"}</p>
          </div>
        ) : (
          <p>Sign Up</p>
        )}
      </PrimaryButton>
    </div>
  );
};

export default PrefixCaptcha;
