import { m } from "framer-motion";

class Utility {
  static userType = {
    sitters: "sitters",
    movers: "movers",
  }
  static getOppositeType(type) {
    if (type === Utility.userType.sitters) {
      return Utility.userType.movers;
    } else if (type === Utility.userType.movers) {
      return Utility.userType.sitters;
    }
    return type;
  }

  static convertDate(date) {
    return new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
  }
  static formatDate(date) {
    if (!date) return date;

    if (!(date instanceof Date)) date = Utility.convertDate(date);

    // Days and months arrays for formatting
    const daysFull = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract date components
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();

    // Format hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Construct formatted date string
    return `${dayOfWeek}, ${month} ${day} ${hours}:${minutes} ${ampm}`;
  }

  static formatDateSessionTime(seconds, includeDayHours = false) {
    const isNegative = seconds < 0;
    const absSeconds = Math.abs(seconds);

    const calculateTimeComponents = (absSeconds) => {
      const days = Math.floor(absSeconds / 86400);
      const remainingSecondsAfterDays = absSeconds % 86400;

      const hours = Math.floor(remainingSecondsAfterDays / 3600);
      const remainingSecondsAfterHours = remainingSecondsAfterDays % 3600;

      const minutes = Math.floor(remainingSecondsAfterHours / 60);
      const remainingSeconds = remainingSecondsAfterHours % 60;

      return { days, hours, minutes, remainingSeconds };
    };

    const formatWithLeadingZero = (num) => (num < 10 ? `0${num}` : num);

    const formatDays = (days) => (days > 0 ? `${days}d : ` : "");

    const formatHours = (hours, days, includeDayHours) =>
      days > 0 || includeDayHours ? `${formatWithLeadingZero(hours)}h : ` : "";

    const formatMinutes = (minutes, includeDayHours) =>
      includeDayHours
        ? `${formatWithLeadingZero(minutes)}m : `
        : `${formatWithLeadingZero(minutes)} : `;

    const formatSeconds = (seconds, includeDayHours) =>
      includeDayHours
        ? `${formatWithLeadingZero(seconds)}s`
        : formatWithLeadingZero(seconds);

    const { days, hours, minutes, remainingSeconds } =
      calculateTimeComponents(absSeconds);

    const formattedDays = formatDays(days);
    const formattedHours = formatHours(hours, days, includeDayHours);
    const formattedMinutes = formatMinutes(minutes, includeDayHours);
    const formattedSeconds = formatSeconds(remainingSeconds, includeDayHours);

    const formattedTime = `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`;

    return `${isNegative ? "-" : ""}${formattedTime.trim()}`;
  }


  static getConsecutiveBreakCount(arr, currentDaterIdx, direction = "forward") {
    let index = currentDaterIdx;

    let count = 0;

    if (direction === "forward") {
      while (true) {
        index = (index + 1) % arr.length;

        if (arr[index].isOnBreak === true) {
          count++;
        } else {
          break;
        }

        if (count == arr.length) break;
      }
    } else {
      // when current user is female, count backwards and wrap around
      console.log("backward");
      while (true) {
        index = (index - 1 + arr.length) % arr.length;
        if (arr[index].isOnBreak === true) {
          count++;
        } else {
          break;
        }

        if (count == arr.length) break;
      }
    }
    return count;
  }

  static calculateRoundRobinDistance(number, round, modulo, reverse) {
    const total = reverse ? number - (round - 1) : number + (round - 1);
    let distance = total % modulo;
    if (distance < 0) {
      distance += modulo;
    }
    return distance;
  }
}

export default Utility;
